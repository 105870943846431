import 'core-js/stable';
import 'regenerator-runtime/runtime';

import initDriverAutocomplete from '../../packs/order/index';

import * as ActionCable from 'actioncable';

function playSound() {
  let audio = new Audio();
  audio.src = '/audio/sms-alert-3-daniel_simon.mp3';
  audio.play();
}

let App = {};

App.channelParams = { channel: 'OrderChannel' };


if (_.isEmpty(gon.user_location_ids))
  if (_.isEmpty(gon.store_name_arr))
    App.channelParams.store_name = gon.store_name;
  else App.channelParams.store_name_arr = gon.store_name_arr;
else App.channelParams.user_location_ids = gon.user_location_ids;

App.cable = ActionCable.createConsumer();


App.order = App.cable.subscriptions.create(
  { ...App.channelParams },
  {
    connected(e) {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      if (data['driver_id'] && data['driver_status']) {
        $('.assignDriverField').each(
          (_index, field) => {
            const el = $(field);
            const elId = el.attr('id');
            const driverId = el.data('driver-id');

            if (['inactive'].includes(data['driver_status'])) {
                $(`#${elId} > option`).each(function () {
                  if (data['driver_id'] == this.value) {
                    gon.drivers = data['drivers']
                    $(this).remove();
                    return false;
                  }
                })

            } else if (['active'].includes(data['driver_status'])){
              gon.drivers = data['drivers']
              $(el).select2({
                placeholder: 'Select a Driver',
                data: gon.drivers.map(driver => ({
                  id: driver.id,
                  text: `${driver.last_name}, ${driver.first_name}`,
                  selected: driverId === driver.id
                }))
              });
            }
          });
      }

      const orderElement = $('#' + data['id']);
      const rowIsFound = orderElement.length;
      let orderIsNew = false;
      if (['Delivered', 'OnDelivery', 'Picked Up', 'Shipped', 'Packed'].includes(data['status'])) {
        orderIsNew = true;
      }

      if (rowIsFound) {
        orderElement.replaceWith(data['order']);
      }else if (!orderIsNew) {
        if(data['in_store_pickup'] == true){
          $('#retial_orders_table_body').prepend(data['order']);
        } else if (!data['driver_has_order']) {
          $('#orders_table_body').prepend(data['order']);
        }
      }

      if (['Open'].includes(data['status'])  || ['Packed'].includes(data['status']) || (['Confirmed'].includes(data['status']) && data['is_mobile']) || data['scheduled']) playSound();

      setTimeout(() => {
        initDriverAutocomplete(data.id);
      });
    },

    new_order() {
      perform('new_order');
    },
  }
);
